import React, { useState, useEffect } from 'react'
import emailjs from '@emailjs/browser';
import { Button, Stack, Snackbar } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Phone, Email, LocationOn } from '@mui/icons-material';
import back from "../../images/build.png";
import NavigationBar from '../components/navigationbar/page';
import useTheme from '@mui/system/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Alert from '@mui/material/Alert';
import { Dialog, DialogActions, } from '@mui/material';
                              
import app from "../../config/firebase"; // Adjust the path accordingly
import { auth, onAuthStateChanged } from "../../config/firebase";
import { getFirestore } from "../../config/firebase";
import { GoogleAuthProvider } from "../../config/firebase";



export const ContactUS = () => {
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const [isValidEmail, setIsValidEmail] = useState(true);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  useEffect(() => {
    // Listen for changes in Firebase authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // If user is authenticated, set the email in the state
        setEmail(user.email || '');
        setIsValidEmail(true); // Assuming authenticated user's email is always valid
      } else {
        setEmail(''); // Clear email if user is not authenticated
        setIsValidEmail(false);
      }
    });

    return () => unsubscribe(); // Cleanup subscription on component unmount
  }, []);
  
  const validateForm = () => {
    if (!email.trim() || !message.trim()) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Please fill in all required fields.');
      setSnackbarOpen(true);
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form before submission
    if (!validateForm()) {
      return;
    }

    const serviceId = 'service_3p9646n';
    const templateId = 'template_t5akwdf';
    const publicKey = 'D_d_xRoHi2x6HvYqW';

    // Create a new object that contains dynamic template params
    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: 'Admin',
      message: message,
    };

    const clearFormFields = () => {
      setMessage('');
    };

     // Use emailjs to send the email
     emailjs
     .send(serviceId, templateId, templateParams, publicKey)
     .then((response) => {
       console.log('Email sent successfully:', response);

      // Open the success modal after successful submission
      handleOpenSuccessModal();

      // Clear form fields after successful submission
      clearFormFields();
     })
     .catch((error) => {
       setSnackbarSeverity('error');
       setSnackbarMessage('Error sending Message. Please try again.');
       setSnackbarOpen(true);
       console.error('Error sending email:', error);
       
     });
 };

 const handleSnackbarClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setSnackbarOpen(false);
};


  return (
    <Grid
      container
      component="main"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${back})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "110% 110%",
        backgroundPosition: "center",
        overflow: "hidden",
        height: isMobile ? '90vh' : "100vh", // Set height to 100% of the viewport height
        overflow: "auto", // Enable scrolling
      }}
    >
      <NavigationBar />
      <Grid container justifyContent="center" paddingTop={4}>
        <Grid item xs={12} sm={6} 
        sx={{
          paddingLeft: isMobile ? 4 : '10%',
          paddingTop: isMobile ? '35px': 0,
          paddingBottom: isMobile ? '10px' : 0,
          }}>

                {/* Snackbar for displaying messages */}
                <Dialog open={openSuccessModal} onClose={handleCloseSuccessModal}>
                  <Alert severity="success" sx={{ marginTop: 2, marginLeft: 2, marginRight: 2, padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {/* Customized message */}
                    <h3>Message sent successfully!</h3>
                    <p>We appreciate your feedback.</p>
                  </Alert>
                  <DialogActions sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant='outlined' onClick={handleCloseSuccessModal} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>

          <Paper elevation={5} 
          sx={{ p: 3, height: '100%',
          width: isMobile ? '102.9%' : '90%', 
          border: '2px solid  #000000',
          borderRadius: '12px',
          boxShadow: 1,
          marginLeft: isMobile ? -2 : 4, // Adding left margin only on mobile view
          backgroundColor: '#fff', }}>
            <Typography variant="h4" color="#2196F3" gutterBottom>
              Send us a message
            </Typography>
            <Typography paragraph>
              We would love to respond to your queries and help you succeed.
              <Typography paragraph>
                Feel free to get in touch with us.
              </Typography>
              <Typography variant="h5" color="#2196F3" gutterBottom>
                Leave us a feedback
              </Typography>
            </Typography>

            <form id="contactForm" className="contactForm" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={11.5}> 
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!isValidEmail}
                    helperText={!isValidEmail && 'Please enter a valid and registered email.'}
                    disabled={isValidEmail} // Disable the field if the email is valid and registered
                  />
                </Grid>
                <Grid item xs={11.5}>
                  <TextField
                    fullWidth
                    label="Message"
                    name="message"
                    variant="outlined"
                    multiline
                    rows={isMobile ? 3 : 7} // Adjusting rows based on the screen size
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Grid>
                <Stack pt={isMobile ? 2 : 2} width={'100%'} sx={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}>
                  <Box paddingTop={'20px'} sx={{ textAlign: 'center', width: '100%' }}>
                    <Button type="submit" variant="contained" color="primary"  sx={{ width: isMobile ? '92.1%' : '92.1%' }}>
                      SUBMIT
                    </Button>
                  </Box>
                </Stack>
              </Grid>
            </form>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ marginLeft: "" }}>
          <Paper elevation={3} 
          sx={{ 
            p: 3,
            height: 'auto',
            width: isMobile ? '95%' : '75%', 
            background: 'linear-gradient(360deg, #528AD7 0%, rgba(49, 39, 157, 1) 49%)', 
            color: '#fff',  
            border: '2px solid  #000000',
            borderRadius: '12px',
            boxShadow: 1,
            marginLeft: isMobile ? 2 : 3, // Adding left margin only on mobile view
            backgroundColor: '#fff', }}>
            <Typography variant="h4" color="#ffffff" gutterBottom>
              Reach Us At
            </Typography>
            <Typography paragraph color="#ffffff">
              We're open for any suggestion or just to have a chat.
            </Typography>
            <div className="dbox" style={{ display: 'flex', alignItems: 'center', marginBottom:  isMobile ? '5px' : '20px', paddingBottom: '20px' }}>
              <LocationOn style={{ marginRight: '8px', color: "#ffffff" }} />
              <Typography variant="body1" color="#ffffff">
                <strong>Address:</strong> 609 San Miguel Street, Canbanua, Argao, Cebu
              </Typography>
            </div>
            <div className="dbox" style={{ display: 'flex', alignItems: 'center', marginBottom: isMobile ? '5px' : '20px', paddingBottom: '20px' }}>
              <Phone style={{ marginRight: '8px', color: "#ffffff" }} />
              <Typography variant="body1" color="#ffffff">
                <strong>Phone:</strong> <a>09873761921</a>
              </Typography>
            </div>
            <div className="dbox" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
              <Email style={{ marginRight: '8px', color: "#ffffff" }} />
              <Typography variant="body1" color="#ffffff">
                <strong>Email:</strong> <a>argaonationalhighschool@gmail.com</a>
              </Typography>
            </div>

            <div className="dbox" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: isMobile ? '10px' : '20px' }}>
              {/* Embed Google Map */}
              <iframe
                width="500px"
                height="200px"
                frameBorder="0"
                style={{ border: '2px solid #000000', borderRadius: '12px' }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3930.5700533854533!2d123.59445421152765!3d9.886391390172475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x33abc5ea58d9353d%3A0xea5e997821c98808!2sArgao%20National%20High%20School!5e0!3m2!1sen!2sph!4v1704589471716!5m2!1sen!2sph"
                allowFullScreen
                title="Google Map"
              ></iframe>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};