import { Button, colors, Stack, useTheme, CircularProgress, emphasize } from "@mui/material";
import { useContext, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react'
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import afc from '../../images/office.jpg';
import LOGO from '../../images/anhslogo.png';
import { role } from "./components/list-items";
import { auth, db } from "../../config/firebase";
import { createUserWithEmailAndPassword,        
    signInWithEmailAndPassword,
    signOut
} from "firebase/auth";
import Alert from "@mui/material";

import { ValidationModal } from "./components/components";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';

export const SignupPage = () =>{
    const [showLRN, setShowLRN] = useState(false);
    const [lrn, setLRN] = useState('');
    const [lrnError, setLRNError] = useState('');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const navigate = useNavigate('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [userRole, setUserRole] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [emailMsg, setEmailMsg] = useState('');
    const [passwordMsg, setPasswordMsg] = useState('');
    const [conMsg, setConMsg] = useState('');

    const [modalBackground, setModalBackground] = useState('');
    const [title, setTitle] = useState('');
    const [msg, setMessage] = useState('');
    const [reg, setReg] = useState(false);
    const [open, setOpen] = useState(false); // State for modal open

    const [agreed, setAgreed] = useState(false); // to track if whether the user has agreed to the terms and privacy conditions.
    const [errorMessage, setErrorMessage] = useState('');


    const handleRoleChange = (selectedRole) => {
        setUserRole(selectedRole);

        // Check if the selected role is "Student" and show/hide LRN input accordingly
         if (selectedRole === 'Student') {
      setShowLRN(true);
      setValidationError((prevErrors) => ({ ...prevErrors, lrn: false })); // Reset LRN validation when role changes to "Student"
    } else {
        setShowLRN(false);
        setLRN(''); // Clear LRN value if the role is not "Student"
        setValidationError((prevErrors) => ({ ...prevErrors, lrn: false })); // Reset LRN validation when role changes to non-"Student"
      }
    };

    //prevents user to go back to the registration page if user is logged
    const checkLoginStatus = () => {
        if (localStorage.getItem('cache')) {
            navigate('/home');
        }
    };

    useEffect(() => {
        checkLoginStatus();
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        if(reg === true){
        navigate('/login');
        }
    };

    //for validation
    const [validationError, setValidationError] = useState({
        firstname: false,
        lastname: false,
        role: false,
        phone: false,
        address: false,
        email: false,
        password: false,
        confirm: false,
        lrn: false, // Add lrn to the validationError state
    });

    const handleValidation = () => {
        const isPhilippinePhoneNumber = /^(09|\+639)\d{9}$/; 
        const updatedValidationError = {
            firstname: !firstname.trim() || !/^[A-Za-z ]+$/.test(firstname),
            lastname: !lastname.trim() || !/^[A-Za-z ]+$/.test(lastname),
            role: !userRole.trim(),
            phone: !contact.trim() || !isPhilippinePhoneNumber.test(contact),
            address: !address.trim(),
            email: !email.trim(),
            password: !password.trim(),
            confirm: !confirm.trim(),
        };

        //LRN error validation and trapping!!
            if (showLRN) {
                const isNumeric = /^\d+$/;
                const isExactly12Digits = /^\d{12}$/;

                if (!lrn.trim()) {
                setLRNError('LRN is required'); 
                setValidationError((prevErrors) => ({ ...prevErrors, lrn: true }));
                return false;
                } else if (!isNumeric.test(lrn.trim()) || !isExactly12Digits.test(lrn.trim())) {
                    
                setLRNError('LRN must be exactly 12 digits and contain only numerical characters');
                setValidationError((prevErrors) => ({ ...prevErrors, lrn: true }));
                return false;
                }
            }
    
        setValidationError(updatedValidationError);
        setEmailMsg('Email is Required');
        setPasswordMsg('Password is Required');
        setConMsg('Password is Required');
        // Return true if there are no validation errors
        return !Object.values(updatedValidationError).some((error) => error);
      };
    
    //sets password visibility
    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    
    //checks if password and confirm
    const handlePasswordValidation = () =>{
        if(password.length < 6){
            const updatedValidationError = {
                password: true,
                confirm: true,
              };
              setPasswordMsg('Password must be 6 characters or more');

              setConMsg('');
              setValidationError(updatedValidationError);
              // Return true if there are no validation errors
              return !Object.values(updatedValidationError).some((error) => error);
        }else{
            if(password !== confirm){
                const updatedValidationError = {
                  password: true,
                  confirm: true,
                };
                setPasswordMsg('Password does not match');
                setConMsg('');
                setValidationError(updatedValidationError);
                // Return true if there are no validation errors
                return !Object.values(updatedValidationError).some((error) => error);
              }else{
                const updatedValidationError = {
                  password: false,
                  confirm: false,
                };
                setPasswordMsg('');
                setConMsg('');
                setValidationError(updatedValidationError);
                return true;
              }
        }
        
        
    }

    const handleRegisterUser = async (user) => {
        const key = user.uid;
        console.log(key);
        const usersCollectionRef = collection(db, 'users'); // No need to include the document ID here
        try {
          const userInformation = await addDoc(usersCollectionRef, {
            userId: key,
            firstname: firstname,
            lastname: lastname,
            contactNumber: contact,
            address: address,
            email: email,
            userRole: userRole,
            accountStatus: 'active',
            accountRegisteredDateTime: serverTimestamp(),
          });
      
          console.log(userInformation);
      
          setMessage('You can now log in with your registered account.');
          setTitle('Your Account Has Been Successfully Registered!');
          setModalBackground( 'linear-gradient(90deg, rgba(173, 216, 230, 0.5), rgba(70, 130, 180, 0.5))');
          setReg(true);
          handleOpen();
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      };
      

    const handleSignUp = async () =>{
        setLoading(true);

        if (!agreed) {
            // Display an error message or take appropriate action if the user has not agreed.
            alert("Please agree to the Terms & Conditions and Privacy & Conditions before signing up.");
            setLoading(false);
            return;
        }

        if (handleValidation()) {
            if (handlePasswordValidation()) {
              try {
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        
                // Access the user information
                const user = userCredential.user;
        
                return handleRegisterUser(user);
        
              } catch (err) {
                console.error(err);
        
                // Handle specific error: auth/invalid-email
                if (err.code === 'auth/invalid-email') {
                  setEmailMsg('Invalid email');
                  setValidationError((prevErrors) => ({ ...prevErrors, email: { error: true, message: 'Invalid email' } }));
                } else {
                  // Handle other errors here
                  setEmailMsg('Email address is already in use. Please use a different email address.');
                  setValidationError((prevErrors) => ({ ...prevErrors, email: { error: true, message: 'Email address is already in use. Please use a different email address.' } }));
                }
        
                setTitle('Error');
                setModalBackground('#ff2929');
                setReg(true);
                setMessage('Error occurred during registration');
        
              } finally {
                setLoading(false);
              }
            } else {
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
        };

    return(
        <Grid container component="main" sx={{height: '100vh' }} justifyContent={"center"} overflow={"auto"} >

            <Grid
                item
                xs={false}
                sm={4}
                md={5}
                sx={{
                    backgroundImage: `url(${afc})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'left',
                  
                  
                }}    
            />
                
                <Grid bgcolor={'#FFFBF5'}   item xs={12} sm={8} md={7} elevation={6} square container justifyContent="center" alignItems="center">
                
                    <Box bgcolor={'white'} 
                    // mobile view
                    sx={{ 
                        width: '100%',
                        height:'100%' ,
                        padding: 3,
                        pt:3,
                        paddingBottom: isMobile ? '' : '50px',
                        boxShadow: 2,
                        //desktop view
                          '@media (min-width:600px)': {
                            width: '75%',
                            height:'95%',
                            paddingTop: 2,
                             
                          },
                          }}>
                        <Stack spacing={2}>

                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} 
                        sx={{ //desktop view
                          '@media (min-width:600px)': {
                            width: '95%',
                            
                          },}}>
                           
                        <div>
                            <Typography variant="h1" paddingBottom={2} component="div" style={{ color: '#528AD7', fontWeight: 'bold', fontSize: '2rem', }}>
                                Archive Hub
                            </Typography>
                            <Typography variant="h3" sx={{ color: '#363636', }}>
                                Create an Account
                            </Typography>
                        </div>

                            <img src={LOGO} style={{ height: '120px',  }}  />
                        </Stack>

                            
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3}
                            sx={{ //desktop view
                                '@media (min-width:600px)': {
                                direction:'row',
                                alignItems:'center',
                                justifyContent:'space-between',
                                spacing:2,
                                  }
                            }}>   
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="First Name"
                                name="firstname"
                                autoFocus
                                onChange={(e) => setFirstname(e.target.value)}
                                error={validationError.firstname}
                                helperText={validationError.firstname ? 'The first name must consist of only alphabetical characters' : ''}
                                onFocus={() => setValidationError({ ...validationError, firstname: false })}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="Last Name"
                                name="lastname"
                                onChange={(e) => setLastname(e.target.value)}
                                error={validationError.lastname}
                                helperText={validationError.lastname ? 'The last name must consist of only alphabetical characters' : ''}
                                onFocus={() => setValidationError({ ...validationError, lastname: false })}
                            />
                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Role *"
                                style={{ width: '100%' }}
                                onChange={(e) => handleRoleChange(e.target.value)}
                                error={validationError.role}
                                helperText={validationError.role ? 'Role is required' : ''}
                                onFocus={() => setValidationError({ ...validationError, role: false })}
                            >
                                {role.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="phoneNumber"
                                label="Phone Number"
                                name="phonenumber"
                                autoComplete="phonenumber"
                                // add telphone not text
                                onChange={(e) => setContact(e.target.value)}
                                error={validationError.phone}
                            helperText={validationError.phone ? 'Please provide a valid phone number with exactly 11 digits, using only numerical characters.' : ''}
                            onFocus={() => setValidationError({ ...validationError, phone: false })}
                            />
                         </Stack>

                        
                            {showLRN && (
                                <TextField
                                required
                                margin="normal"
                                fullWidth
                                id="lrn"
                                label="LRN (Learner Reference Number)"
                                name="lrn"
                                type="text"
                                onChange={(e) => setLRN(e.target.value)}
                                error={lrnError}
                                helperText={lrnError ? 'LRN must be exactly 12 digits and contain only numerical characters' : ''}
                                onFocus={() => setLRNError(false)}
                                />
                            )}

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="address"
                                label="Address"
                                name="address"
                                type="text"
                                onChange={(e) => setAddress(e.target.value)}
                                error={validationError.address}
                                helperText={validationError.address ? 'Address is required' : ''}
                                onFocus={() => setValidationError({ ...validationError, address: false })}
                            />

                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                error={validationError.email}
                                helperText={validationError.email ? emailMsg : ''}
                                onFocus={() => setValidationError({ ...validationError, email: false })}
                            />

                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={(e) => setPassword(e.target.value)}
                                    error={validationError.password}
                                    helperText={validationError.password ? passwordMsg : ''}
                                    onFocus={() => setValidationError({ ...validationError, password: false })}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="confirmPassword"
                                    label="Confirm Password"
                                    name="confirmpassword"
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={(e) => setConfirm(e.target.value)}
                                    InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                        <IconButton onClick={togglePasswordVisibility}>
                                            {showPassword ?  <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                        </InputAdornment>
                                    ),
                                    }}

                                    error={validationError.confirm}
                                    helperText={validationError.confirm ? conMsg : ''}
                                    onFocus={() => setValidationError({ ...validationError, confirm: false })}
                                />
                            </Stack>



                            <Stack direction="column" alignItems="left" justifyContent="space-between" 
                            sx={{
                            fontSize: '2px',
                            width: '100%',
                             //desktop view
                          '@media (min-width:600px)': {
                            width: '73%',
                            fontSize: '5px',
                             
                          },
                             }}>
                                <FormControlLabel  control={<Checkbox checked={agreed} onChange={() => setAgreed(!agreed)}  />} 
                                label="I agree to both Terms & Conditions and Privacy Policy" />
                            </Stack>

                            <Button   
                                onClick={handleSignUp}
                                variant="contained" 
                                size="large"
                                sx={{ color: 'white', backgroundColor: 'main', position: 'relative' }}
                                disabled={loading || !agreed} // Disable the button when loading or if not agreed
                            >
                            {loading && <CircularProgress size={24} sx={{ color: 'white', position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px' }} />} 
                            {/* Show CircularProgress when loading */}
                                Sign Up
                            </Button>

                            <Divider gutterBottom variant="body1"/>

                            <Stack direction="row" alignItems="center" justifyContent="center"  spacing={4}>
                                <Typography variant="body1">
                                    Already have an Account?{' '}
                                    <Button href="login" variant="text" sx={{ textTransform: 'none',  }}>Sign in Here!</Button>
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                </Grid>
                <ValidationModal open={open} handleClose={handleClose} title={title} description={msg} alertColor={modalBackground} success={reg}/>
         </Grid>   
    )
}