import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import image from "../../images/logonoback.png";
import { Button } from '@mui/material';

export const UnderMaintenance = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        position:'flex',
        marginTop: '10rem',
        textAlign: 'center',
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundSize: '25%',
        backgroundPosition: 'top',
        padding: '1rem',         
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        
        '@media (min-width:600px)': {
            position:'flex',
            marginTop: '8rem',
            textAlign: 'center',
            backgroundImage: `url(${image})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundSize: '10%',
            backgroundPosition: 'top',
            padding: '7rem',       
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',   
        },
      }}
    >
        <Box alignItems={'center'} 
        sx={{
            padding:'10px',
            height:'100%',
            paddingTop:'25%',
            textAlign:'center',
            '@media (min-width:600px)': {
                padding:'20px',
                textAlign:'center',
              },
            }}>
            <Typography variant="h4" gutterBottom>
                Under Maintenance
            </Typography>
            <Typography variant="body1" paragraph>
                We apologize for the inconvenience. Our site is currently undergoing maintenance.
                Please check back later.
            </Typography>

      <Button href='/home' variant="contained" color="primary" style={{ position: 'flex', }}>
        Return to Home
      </Button>
        </Box>
      
    </Container>
  );
};
