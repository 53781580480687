import React from 'react'
import { useCache } from './cache-provider';
import { auth } from '../config/firebase';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router';

export const Logout = () => {
  const { removeFromCache} = useCache();
  const navigate = useNavigate('');

  const signOutUser = async () =>{
    try{
      await signOut(auth)
      .then(() => {
          removeFromCache()
          localStorage.clear();
          navigate('/landing'); // Use navigate to redirect to the desired route
      })
      .catch((error) => {
          // Handle any potential errors during logout
          console.error('Logout failed: ', error);
      });
    }catch(err){
      console.log(err)
    }
  }

  signOutUser();
  return (
    <></>
  )
}
