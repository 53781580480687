import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Paper, useTheme } from '@mui/material';
import NavigationBar from '../components/navigationbar/page';
import tae from '../../images/out.png';
import depedLogo from '../../images/deped-logo.png'; 
import useMediaQuery from '@mui/material/useMediaQuery';

export const AboutPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid
      container
      component="main"
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflowX: 'auto',
        backgroundImage: `url(${tae})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <NavigationBar />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          m: '3px',
          height: isMobile? '100%' : '90%',
          overflowY: 'auto',
          maxHeight: 'calc(100% - 64px)',
          flexGrow: 1, 
          
          '@media (min-width:600px)': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            m: '20px',
            height: '80%',
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 64px)',
          },
        }}
      >

        <Paper
          elevation={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '94%', // Adjusted width for responsiveness
            padding: theme.spacing(3), // Responsive padding using the theme spacing
            [theme.breakpoints.up('md')]: {
              width: '70%', // Adjusted width for medium-sized screens and larger
            },
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            mb: theme.spacing(3),
            borderRadius: '12px',
            textAlign: 'center',
            borderImage: 'linear-gradient(45deg, #00B4DB, transparent) 1 100%', // Diagonal border
            borderImageSlice: 1,
            boxShadow: '0px 0px 10px rgba(0, 180, 219, 0.7), 0px 0px 20px rgba(0, 180, 219, 0.5), inset 0px 0px 10px rgba(0, 180, 219, 0.5)',
          }}
        >
          <Grid
  container
  alignItems="center"
  spacing={.5}
>
  <Grid item>
    {/* Insert DepEd logo here */}
    <img src={depedLogo} alt="DepEd Logo" style={{ width: '160px', height: '80px', borderRadius: '10%' }} />
  </Grid>
  <Grid item>
           <Typography
  variant="h4"
  gutterBottom
  sx={{
    backgroundImage: 'linear-gradient(45deg, #FF4D4D, #FF8C00)', // Adjusted gradient stops for a more appealing red color
    WebkitBackgroundClip: 'text',
    color: 'transparent', // Hide the actual text color
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)', // Shadow effect
    WebkitTextStroke: '1px rgba(255, 255, 255, 0.7)', // Glow effect
    display: 'inline-block',
    fontSize: '35px',
    
  }}
>
  VISION
</Typography>
</Grid>
</Grid>


          <Grid
  container
  direction="row"
  alignItems="flex-start"
  spacing={2}
>
  <Grid item>
    {/* Your Typography component */}
    <Typography variant="body1" style={{fontSize:'15px', letterSpacing:'1px'}}>
    We dream of Filipinos who passionately love their country and whose competencies and values enable them to 
    realize their full potential and contribute meaningfully to building the nation. We are a learner-centered public 
    institution that continuously improves itself to pursue its mission.
    </Typography>
  </Grid>

      
          </Grid>
        </Paper>
        <Paper
          elevation={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '94%', // Adjusted width for responsiveness
            padding: theme.spacing(3), // Responsive padding using the theme spacing
            [theme.breakpoints.up('md')]: {
              width: '70%', // Adjusted width for medium-sized screens and larger
            },
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            mb: theme.spacing(3),
            borderRadius: '12px',
            textAlign: 'center',
            borderImage: 'linear-gradient(45deg, #00B4DB, transparent) 1 100%', // Diagonal border
            borderImageSlice: 1,
            boxShadow: '0px 0px 10px rgba(0, 180, 219, 0.7), 0px 0px 20px rgba(0, 180, 219, 0.5), inset 0px 0px 10px rgba(0, 180, 219, 0.5)',
          }}
        >

  <Grid
  container
  alignItems="center"
  spacing={.5}
>
  <Grid item>
    {/* Insert DepEd logo here */}
    <img src={depedLogo} alt="DepEd Logo" style={{ width: '160px', height: '80px', borderRadius: '10%' }} />
  </Grid>
  <Grid item>


       <Typography
  variant="h4"
  gutterBottom
  sx={{
    backgroundImage: 'linear-gradient(45deg, #FF4D4D, #FF8C00)', // Adjusted gradient stops for a more appealing red color
    WebkitBackgroundClip: 'text',
    color: 'transparent', // Hide the actual text color
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)', // Shadow effect
    WebkitTextStroke: '1px rgba(255, 255, 255, 0.7)', // Glow effect
    display: 'inline-block',
    fontSize: '35px'
  }}
>
  MISSION
</Typography>

</Grid>
</Grid>

        <Grid
  container
  direction="row"
  alignItems="flex-start"
  spacing={2}
>
  <Grid item>
    {/* Your Typography component */}
    <Typography variant="body1" style={{fontSize: '15px', letterSpacing:'1px'}}>
      To protect and promote the right of every Filipino to quality, equitable, culture-based, and complete basic education where:
      <br />
      - Students learn in a child-friendly, gender-sensitive, safe, and motivating environment.
      <br />
      - Teachers facilitate learning and constantly nurture every learner.
      <br />
      - Administrators and staff, as stewards of the institution, ensure an enabling and supportive environment for effective learning to happen.
      <br />
      - Family, community, and other stakeholders are actively engaged and share responsibility for developing life-long learners.
    </Typography>
  </Grid>
</Grid>
        </Paper>

        <Paper
          elevation={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '94%', // Adjusted width for responsiveness
            padding: theme.spacing(3), // Responsive padding using the theme spacing
            [theme.breakpoints.up('md')]: {
              width: '70%', // Adjusted width for medium-sized screens and larger
            },
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            mb: theme.spacing(3),
            borderRadius: '12px',
            textAlign: 'center',
            borderImage: 'linear-gradient(45deg, #00B4DB, transparent) 1 100%', // Diagonal border
            borderImageSlice: 1,
            boxShadow: '0px 0px 10px rgba(0, 180, 219, 0.7), 0px 0px 20px rgba(0, 180, 219, 0.5), inset 0px 0px 10px rgba(0, 180, 219, 0.5)',
          }}
        >
  <Grid
  container
  alignItems="center"
  spacing={.5}
>
  <Grid item>
    {/* Insert DepEd logo here */}
    <img src={depedLogo} alt="DepEd Logo" style={{ width: '160px', height: '80px', borderRadius: '10%' }} />
  </Grid>
  <Grid item>


       <Typography
  variant="h4"
  gutterBottom
  sx={{
    backgroundImage: 'linear-gradient(45deg, #FF4D4D, #FF8C00)', // Adjusted gradient stops for a more appealing red color
    WebkitBackgroundClip: 'text',
    color: 'transparent', // Hide the actual text color
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)', // Shadow effect
    WebkitTextStroke: '1px rgba(255, 255, 255, 0.7)', // Glow effect
    display: 'inline-block',
    fontSize: '35px'
  }}
>
  CORE VALUES
</Typography>

</Grid>
</Grid>

<Grid
  container
  direction="row"
  alignItems="center"  // Use "center" instead of "flex-end"
  justifyContent="center"  // Add this line to center items horizontally
  spacing={2}
  sx={{marginBottom: isMobile ? '100px' : ''}}
  
>
  <Grid item  >
    {/* Your Typography component */}
    <Typography variant="body1" style={{fontSize: '15px', letterSpacing: '1px'}}>
      <div style={{ maxHeight: '120px', overflowY: 'auto' }}>
        <Typography variant="body1" style={{ fontSize: '15px', paddingBottom: '8px' }}>
          Maka-Diyos
        </Typography>
        <Typography variant="body1" style={{ fontSize: '15px', paddingBottom: '8px' }}>
          Maka-tao
        </Typography>
        <Typography variant="body1" style={{ fontSize: '15px', paddingBottom: '8px' }}>
          Makakalikasan
        </Typography>
        <Typography variant="body1" style={{ fontSize: '15px'}}>
          Makabansa
        </Typography>
      </div>
    </Typography>
  </Grid>
</Grid>
        </Paper>

      </Box>
    </Grid>
  );
};
