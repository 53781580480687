  import * as React from 'react';
  import { Button,Stack, useTheme } from "@mui/material";
  import { useContext, useEffect, useState } from "react";
  import Grid from '@mui/material/Grid';
  import Typography from '@mui/material/Typography';
  import Avatar from '@mui/material/Avatar';
  import NavigationBar from '../components/navigationbar/page';
  import image from '../../images/frontSchool.png';
  import ChevronRightIcon from '@mui/icons-material/ChevronRight';
  import styled, { keyframes } from 'styled-components';
  import useMediaQuery from '@mui/material/useMediaQuery';
  import { Users } from 'react-feather';
  import { collection, getDocs, query, where } from 'firebase/firestore';
  import { db , auth} from '../../config/firebase';


  export const HomePage = () =>{
    // Get the user's name 
    const [firstName, setFirstName] = useState('');
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      } else if (event.key === 'Escape') {
        setOpen(false);
      }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }

      prevOpen.current = open;
    }, [open]);



    const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const LetterPopUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedTypography = styled(Typography)`
  display: inline-block;
  position: relative;

  span {
    display: inline-block;
    opacity: 0;
    transform: translateY(10px);
    animation: ${LetterPopUp} 0.5s ease-in-out forwards;
  }

  span:nth-child(1) {
    animation-delay: 0.1s;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  /* Add more span:nth-child(n) rules for additional letters */
`;
useEffect(() => {
  const fetchUserData = async () => {
    try {
      // Get the currently authenticated user
      const user = auth.currentUser;

      if (user) {
        const userId = user.uid;

        const usersCollection = collection(db, 'users');
        const usersQuery = query(usersCollection, where('userId', '==', userId));

        const usersSnapshot = await getDocs(usersQuery);

        if (!usersSnapshot.empty) {
          const userData = usersSnapshot.docs[0].data();

           // Extract the first name and last name
           const userFirstName = userData.firstname ? userData.firstname.split(' ')[0] : '';
           const userLastName = userData.lastname || ''; // Add this line to extract last name
 
          console.log('userFirstName:', userFirstName);
          console.log('userLastName:', userLastName);

          setFirstName(`${userFirstName} ${userLastName}`);

           // Store user's name in localStorage
           localStorage.setItem('userFirstName', userFirstName);
           localStorage.setItem('userLastName', userLastName);
        } else {
          console.log('User not found in Firestore.');
        }
      } else {
        console.log('No user is currently authenticated.');
      }
    } catch (error) {
      console.error('Error fetching user data from Firestore:', error);
    }
  };

    // Check localStorage for user's name
    const storedFirstName = localStorage.getItem('userFirstName');
    const storedLastName = localStorage.getItem('userLastName');

    if (storedFirstName && storedLastName) {
      setFirstName(`${storedFirstName} ${storedLastName}`);
    } else {
      // Fetch user data if not found in localStorage
      fetchUserData();
    }
  }, []);

      return(
          //Image
          <Grid container component="main" 
          sx={{
              height: "100vh",
              display: "abosulute",
              justifyContent: "center",
              alignItems: "center",
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "110% 110%",
              backgroundPosition: "center",
              overflowX: "auto",
    
            }} >

            
<Grid sx={{ width: { xs: 400, sm: 500, md: 700, lg: 900, xl: 1000 }, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', height: '100vh' }} >

  <NavigationBar />

  <Stack alignItems="center" item>
  <Typography
    variant="h3"
    sx={{
      paddingTop: { xs: '10px', sm: '15px' },
      color: '#fff',
      letterSpacing: '0.8px',
      fontSize: { xs: '40px', sm: '30px' },
      textAlign: 'center', // Set text alignment to center
      width: '100%',
      border: '1px solid rgba(255, 255, 255, 0.5)',
      padding: isMobile ? '1px' : '15px',
      position: isMobile ? 'relative' : 'relative', // Use 'relative' for non-mobile view
      background: 'linear-gradient(90deg, rgba(173, 216, 230, 0.5), rgba(70, 130, 180, 0.5))',
      borderRadius: '45px',
      marginBottom: isMobile ? '20px' : '10px',
    }}
  >
    Welcome to ANHS Archive Hub!
  </Typography>
</Stack>




<Stack alignItems="left" item>
      <AnimatedTypography
        variant="h1"
        sx={{
          color: '#fff',
          letterSpacing: '1px',
          fontSize: isMobile ? '30px' : '62px',
          paddingBottom:'30px'
        }}
      >

       {/* display the firste name and last name of the suer  */}

          {/* {`Hello, ${firstName || 'user'}!`} */}
          {`Hello, ${firstName  || 'user'}!`}
      </AnimatedTypography>
    </Stack>

  <Grid 
     >
   <Typography
  variant="h5"
  style={{
    paddingTop: isMobile ? '3px' : '10px',
    color: '#fff',
    letterSpacing: '1px',
    fontFamily: 'Poppins, sans-serif',
    lineHeight: '1.5',
    paddingBottom:'10px'
  }}
>                             
  All of your information is crucial in ensuring the accuracy and efficiency of requesting documents. We appreciate your cooperation in helping us fulfill your needs. Where efficiency meets excellence. Our mission is to streamline documents requests, ensuring a seamless and secure experience for all user.
</Typography>
  </Grid>
 
  
<Stack>
  <Button href='request' variant="text" sx={{  borderRadius: '10px', marginTop: '90px', marginBottom: isMobile ? '250px' : '150px', padding: '15px 45px', color: 'white', backgroundColor: '#2962ff', textTransform: 'none', ':hover': { bgcolor: '#651fff' }, fontSize: '15px' }}>
    Request Now!
    
  </Button>
  
</Stack>
  </Grid>
  
   
  </Grid>
    

      )
  }