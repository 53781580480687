import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';


import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';



const FireworksContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
  overflow: 'hidden',
  animation: 'fireworks 1s ease-out', // Add fireworks animation
  '@keyframes fireworks': {
    '0%': {
      transform: 'scale(0)',
      opacity: 0,
    },
    '50%': {
      transform: 'scale(0.1)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 0,
    },
  },
}));

const FireworkParticle = styled('div')(({ theme }) => ({
  position: 'absolute',
  background: theme.palette.secondary.main,
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  animation: 'particle 01s ease-out infinite', // Particle animation
  '@keyframes particle': {
    '0%': {
      transform: 'translate(0, 0) scale(1)',
      opacity: 1,
    },
    '50%': {
      transform: 'translate(0, -20px) scale(0.8)',
      opacity: 0.6,
    },
    '100%': {
      transform: 'translate(0, 0) scale(1)',
      opacity: 1,
    },
  },
}));

const CustomDialogTitle = styled(DialogTitle)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const CustomDialogContent = styled(DialogContent)({
  padding: 0,
});



export const ValidationModal =({open, handleClose, title, description, alertColor}) => {

  
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <FireworksContainer>
        <CustomDialogTitle>
         <Typography variant="h6" sx={{ fontSize: '20px', fontWeight: 'bold' }}>
            {title}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </CustomDialogTitle>
        <CustomDialogContent>
          <FireworkParticle style={{ top: '30%', left: '40%' }} />
          <FireworkParticle style={{ top: '60%', left: '60%' }} />
          <FireworkParticle style={{ top: '20%', left: '80%' }} />
          <DialogContentText style={{ textAlign: 'center', padding: '16px' }}>
             <Typography sx={{ fontSize: '16px' }}>{description}</Typography>
          </DialogContentText>
        </CustomDialogContent>
      </FireworksContainer>
    </Dialog>
  );
};