import React, { useEffect ,useState, useRef  } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import { keyframes } from '@emotion/react';
import emailjs from '@emailjs/browser';
//firebase
import { addDoc, collection, serverTimestamp, doc,query, where, getDocs } from "firebase/firestore";
import { auth, db } from "../../../config/firebase";
import { Autocomplete } from '@mui/material';
import '../../request/components/tablemodal.css'

export const TableModal = ({ studentInfo }) =>  {
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [error, setError] = useState('');
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [studentId, setStudentId] = useState([]);
  const [autocompleteInput, setAutocompleteInput] = useState(null);


  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    lrn: '',
    documentType: '',
  });
  const [formAvailable, setFormAvailable] = useState([]);

  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [showAutocomplete, setShowAutocomplete] = useState(false);

  useEffect(() => {
    // Map studentInfo.id to studentId for Autocomplete
    setStudentId(studentInfo.map((student) => student.id));
    setStudentId(studentInfo);
  }, [studentInfo]);

     // Function to generate a random string of characters
   const generateRandomString = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    
    const getRandomChar = (pool) => pool[Math.floor(Math.random() * pool.length)];
  
    let randomString = '';
    for (let i = 0; i < 6; i++) {
      randomString += getRandomChar(characters);
    }
  
    return randomString;
  };
  
     // Function to handle successful form submission alert open
     const handleSuccessAlertOpen = () => {
      setSuccessAlertOpen(true);
    };

    // Function to handle successful form submission alert close
    const handleSuccessAlertClose = () => {
      setSuccessAlertOpen(false);
      // Optionally, you can perform additional actions here if needed
    };

 // Function to handle input changes in the form
 const handleInputChange = (e) => {
  const { name, value } = e.target;
  console.log(name)
  // If the LRN field is being changed
  if (name === 'lrn') {
    // Find the student in studentInfo based on the selected LRN
    const selectedStudent = studentInfo.find((student) => student.id === value);
    console.log(selectedStudent)

    // If the student is found, set firstName and lastName
    if (selectedStudent) {
      setFormData({
        ...formData,
        firstName: selectedStudent.firstname,
        lastName: selectedStudent.lastname,
        [name]: value,
      });
    } else {
      // Clear firstName and lastName if the LRN doesn't match any student
      setFormData({
        ...formData,
        firstName: '',
        lastName: '',
        [name]: value,
      });
    }
  } else {
    // For other input fields, update the form data
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  // Clear the error when the user starts typing
  if (error && value !== '') {
    setError('');
  }

  getDocuments(value);
};

const getDocuments = async (selectedLrn) => {
  console.log('lrn gikuha', selectedLrn)
  try {
    const studentCollectionRef = collection(db, 'students');
    const studentQuerySnapshot = await getDocs(query(studentCollectionRef, where('id', '==', selectedLrn)));

    if (studentQuerySnapshot.size > 0) {
      console.log('naay documents')
      const studentDocRef = studentQuerySnapshot.docs[0].ref;
      const documentCollectionRef = collection(studentDocRef, 'documents');
      const documentSnapshot = await getDocs(documentCollectionRef);

      const resultData = documentSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Check if any document has availability "claimed"
      const isClaimed = resultData.some((document) => document.availability === "unavailable");

      if (!isClaimed) {
        // Set formAvailable only if documents are unclaimed
        setFormAvailable(resultData.map((document) => document.documentType));
        setFormAvailable(resultData);
        console.log('student documents', formAvailable);
      } else {
        // Do something when availability is "claimed", e.g., show a message
        console.log('Documents are claimed. Do something...');
      }
    }
  } catch (err) {
    console.error(err);
  }
};

  const handleOpen = () => {
    setOpen(true);
    setError(''); // Reset error when opening the main modal

    setFormData({
      firstName: formData.firstName,
      lastName: formData.lastName,
      lrn: formData.lrn,
      documentType: formData.documentType,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  // Function to open the confirmation modal
  const handleConfirmationOpen = () => {
    const { firstName, lastName, lrn, documentType } = formData;
  
  // Validate the fields
  if (firstName === '' || lastName === '' || lrn === '' || documentType === '') {
    setError('Please fill in all required fields.'); // Set error if required fields are not filled
  } else {
    let firstNameError = '';
    let lastNameError = '';
    let lrnError = '';

    // Validate First Name
    if (!/^[a-zA-Z ]+$/.test(firstName)) {
      firstNameError = 'First Name should contain only letters.';
    }

    // Validate Last Name
    if (!/^[a-zA-Z ]+$/.test(lastName)) {
      lastNameError = 'Last Name should contain only letters.';
    }

    // Validate LRN
    if (!/^\d{12}$/.test(lrn)) {
      lrnError = 'LRN should be a 12-digit number.';
    }

    // Set errors if any
    if (firstNameError || lastNameError || lrnError) {
      setError(`${firstNameError} ${lastNameError} ${lrnError}`);
    } else {
      setError('');
      setConfirmationOpen(true);
    }
  }
};

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
    setFormData({
      firstName: '',
      lastName: '',
      lrn: '',
      documentType: '',
    });
    handleClose();
  };
  // go back to first modal
  const handleEdit = () => {
    handleConfirmationClose();
    handleOpen();
  };
  // to submit the requested form
  const handleSubmit = async () => {
    const cache = localStorage.getItem('cache');
    const parsedCache = JSON.parse(cache);
    const userId = parsedCache.uid.toString();
    const email = parsedCache.email.toString();
    try {
      const usersCollectionRef = collection(db, 'users');
    
      const userQuery = query(usersCollectionRef, where('userId', '==', userId));
      const userQuerySnapshot = await getDocs(userQuery);
    
      if (!userQuerySnapshot.empty) {
        const userData = userQuerySnapshot.docs[0].data();
        const role = userData;
        console.log(role.userRole)
    
        const req = collection(db, 'requests');
    
        const res = await addDoc(req, {
          requestDateTime: serverTimestamp(),
          role: role.userRole,
          data: 'hello',
        });
    
        console.log(res);
      } else {
        console.log('No user found with the specified userId.');
      }
    } catch (error) {
      console.error('Error uploading role:', error);
    }
    
    try {
      const { firstName, lastName, lrn, documentType } = formData;
      const usersCollectionRef = collection(db, 'users');

      const userQuery = query(usersCollectionRef, where('userId', '==', userId));
      const userQuerySnapshot = await getDocs(userQuery);

        // Check if a document with the userId exists
      if (userQuerySnapshot.size > 0) {
        // Document with the userId exists, get the reference to it
        const userDocRef = userQuerySnapshot.docs[0].ref;

        // Reference to the 'childCollection' collection inside the user's document
        const childCollectionRef = collection(userDocRef, 'requests');

        // Add a document to the 'childCollection'
        const resultData = await addDoc(childCollectionRef, {
          userId: parsedCache.uid.toString(),
          firstname: firstName,
          lastname: lastName,
          requestDateTime: serverTimestamp(),
          lrn: lrn,
          formType: documentType,
          requestCode: generateRandomString(),
          requestStatus: "Pending"
        });

        console.log('Document Data Added to requests:', resultData);

        // Rest of your code
        // ...
      } else {
        console.log('User document with userId does not exist.');
      }


      // console.log('Document Data Added:', resultData);

    const serviceId = 'service_k1ri1es';
    const templateId = 'template_wx73ef8';
    const publicKey = 'op20DCq8c7KHH33k_';

     // Create a new object that contains dynamic template params
     const templateParams = {
      user_requestStatus: 'Pending', // Replace with the actual status
      user_firstName: firstName,
      user_lastName: lastName,
      user_requestType: documentType,
      user_requestedDate: new Date().toLocaleDateString(), // Replace with the actual date
      user_requestCode: generateRandomString(), // Replace with the actual request code
      user_LRN: lrn,
      user_email: email,
    };

     // Use emailjs to send the email
     emailjs.send(serviceId, templateId, templateParams, publicKey)
     .then((response) => {
       console.log('Email has been sent successfully:', response);
     })
     .catch((error) => {
       console.error('Error sending email to admin:', error);
     });

     // Show the success alert after successful submission
     handleSuccessAlertOpen();
    } catch (err) {
      console.error(err);
    }

   

  };
  // to close both modal
  const handleCancel = () => {
    setFormData({
      firstName: '',
      lastName: '',
      lrn: '',
      documentType: '',
    });
    handleClose();
    handleConfirmationClose();
  };

  const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
`;

const handleLRNAutoSuggest = (e) => {
  const inputValue = e.target.value;
  setFormData({ ...formData, lrn: inputValue });

  // Filter studentInfo based on the input value
  const filteredOptions = studentInfo.filter((student) =>
    student.id.toLowerCase().includes(inputValue.toLowerCase())
  );

  setAutocompleteOptions(filteredOptions);
  setShowAutocomplete(true);
};

const handleAutocompleteSelect = (selectedValue) => {
  setFormData({ ...formData, lrn: selectedValue });
  setShowAutocomplete(false);
};
  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Request
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Grow in={open}>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400, // Adjust the width as needed
              backgroundColor: '#fff',
              padding: 20,
              borderRadius: 8,
              outline: 'none',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '-10px' }}>
              <IconButton color="black" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>

            <h2 style={{ textAlign: 'center', marginBottom: '20px' }}> Request Form</h2>
            <Collapse in={!!error} timeout={500}>
              <div style={{ marginTop: '10px', animation: `${bounce} 1s ease` }}>
                <Alert severity="error">
                  <AlertTitle>Oops!</AlertTitle>
                  {error}
                </Alert>
              </div>
            </Collapse>
            
          {/* Use Autocomplete for LRN field */}
          <div className="table-modal">
              {/* ... (rest of the component code) */}
              {/* Custom Autocomplete */}
              <input
                required
                className="autocomplete-input"
                type="text"
                value={formData.lrn}
                onChange={handleLRNAutoSuggest}
                placeholder="LRN* (Learner's Reference Number)"
              />
              {showAutocomplete && (
                <ul className="autocomplete-options">
                  {autocompleteOptions.map((option) => (
                    <li key={option.id} onClick={() => handleAutocompleteSelect(option.id)}>
                      {option.id}
                    </li>
                  ))}
                </ul>
              )}
           </div>
            <TextField
              required
              label="Student's First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              variant="outlined"
              helperText={error && formData.firstName === '' ? 'Enter the required first name ' : ''}
              error={error && formData.firstName === ''}
            />
            <TextField
              required
              label="Student's Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              variant="outlined"
              helperText={error && formData.lastName === '' ?  'Enter the required last name' : ''}
              error={error && formData.lastName === ''}
            />
         
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel htmlFor="documentType">Request Type*</InputLabel>
              <Select
                required
                value={formData.documentType}
                onChange={handleInputChange}
                inputProps={{
                  name: 'documentType',
                  id: 'documentType',
                }}
                label="Request Type"
                helperText={formData.documentType ? '' : 'Select the Request type'}
                error={error && formData.documentType === ''}  
              >
                <MenuItem value="Form137">Form 137</MenuItem>
                <MenuItem value="Good Moral">Good Moral</MenuItem>
                <MenuItem value="Diploma">Diploma</MenuItem>
                <MenuItem value="Report Card">Report Card</MenuItem>
                <MenuItem value="Certificate of Enrollment">Certificate of Enrollment</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleConfirmationOpen} style={{ marginTop: 20, width:'100%' }}>
              Submit
            </Button>
          </div>
        </Grow>
      </Modal>

      <Modal open={confirmationOpen} onClose={handleConfirmationClose}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 8,
            outline: 'none',
          }}
        >
          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Confirm your Request</h2>
           <div style={{ marginBottom: 15, background: formData.firstName ? '#e6f7ff' : 'transparent' ,fontSize: '19px',}}>
            <strong>Student's First Name:</strong> {formData.firstName}
          </div>
          <div style={{ marginBottom: 15, background: formData.lastName ? '#e6f7ff' : 'transparent',fontSize: '19px', }}>
            <strong>Student's Last Name:</strong> {formData.lastName}
          </div>
          <div style={{ marginBottom: 15, background: formData.lrn ? '#e6f7ff' : 'transparent',fontSize: '19px', }}>
            <strong>LRN:</strong> {formData.lrn}
          </div>
          <div style={{ marginBottom: 15, background: formData.documentType ? '#e6f7ff' : 'transparent',fontSize: '19px', }}>
            <strong>Request Type:</strong> {formData.documentType}
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Button variant="contained" color="secondary" onClick={handleEdit} style={{ marginBottom: 10 }}>
              Edit
            </Button>
            <Button variant="contained" color="primary" onClick={() => { handleSubmit(); handleConfirmationClose(); }}  style={{ marginBottom: 10 }}>
              Confirm
            </Button>
            <Button variant="outlined" color="primary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
       {/* Success alert modal */}
       <Modal open={successAlertOpen} onClose={handleSuccessAlertClose}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: '#fff',
            padding: 20,
            borderRadius: 8,
            outline: 'none',
            textAlign: 'center',
          }}
        >
          {/* Success alert title */}
          <h2 style={{ marginBottom: '20px', color: 'green' }}>Request Has Been Submitted Successfully!</h2>
          <p>
            Your request has been submitted successfully. The school (ANHS) will now review your request. 
            Please wait for an email notification regarding the status of your request and instructions on 
            how to obtain your requested document once it's approved.
          </p>
          {/* OK button to close the success alert */}
          <Button variant="contained" color="primary" onClick={handleSuccessAlertClose}>
            OK
          </Button>
        </div>
      </Modal>
    </div>
  );
};

