import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Protected } from "./protected";
import { Root } from "./root";
import { LandingPage } from "../pages/landing/page";
import { SignupPage } from "../pages/register/page";
import { LoginPage } from "../pages/login/page";
import { Logout } from "./logout";
import { ForgetPassword } from "../pages/forgetpassword/page";
import { RequestPage } from "../pages/request/page";
import { HomePage } from "../pages/home/page";
import { AboutPage } from "../pages/about/page";
import { PageNotFound } from "../pages/404/page";
import { VerifyPage } from "../pages/verify/page";
import { UnderMaintenance } from "../pages/under-maintenance/page";
import { ContactUS } from "../pages/contact/page";

const AppRouters = () => {
    const router = createBrowserRouter([
      {
        path: '/',
        element: <Root />,
        children: [
          {
            path: 'landing',
            element: <LandingPage />,
          },
          {
            path: 'signup',
            element: <SignupPage />,
          },
          {
            path: 'verify',
            element: <VerifyPage/>,
          },
          {
            path: 'login',
            element: <LoginPage />,
          },
          {
            path: 'forget',
            element: <ForgetPassword/>,
          },

          {
            path: '/',
            element: <Protected/>,
            children: [ 
              {
                path: '/home',
                element: <HomePage />,
              },
              {
                path: '/request',
                element: <RequestPage/>,
              },
              {
                path: '/about',
                element: <AboutPage />,
              },
              {
                path: '/contact',
                element: <ContactUS/> ,
              },
              {
                path: '/maintenance',
                element: <UnderMaintenance/>,
              },
              {
                path: '/logout',
                element: <Logout/>,
              },
              
            ],
          },
        ],
      },

      // 404 if page does not exist
      {
        path: '*',
        element: <PageNotFound/>
      }
    ]);
  
    return <RouterProvider router={router} />;
  };
  
  export default AppRouters;
  