import * as React from 'react';
import { Button,Stack } from "@mui/material";
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import front from '../../images/frontSchool.png';
import gmail from '../../images/gmail.png';
import fblogo from '../../images/facebookImage.png';
import useTheme from '@mui/system/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

export const LandingPage = () =>{
 
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return(
        <Grid container component="main"  sx={{
              height: "100vh",
              width:'100%',
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${front})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize:'cover',
              overflow: "hidden",
          }} >

          


          
            <Grid sx={{ width: {xs:400, sm:500, md:700, lg:900, xl:1000,} , display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }} >
                <Grid  alignItems="center"  >
                <Typography variant="h1" component="div" style={{
                    background: `linear-gradient(45deg, #2196f3, #4fc3f7)`, // Linear gradient from blue to light blue
                    textAlign: 'center',
                    fontSize: 28,
                    marginTop: isMobile ? '10px' : '50px',
                    padding: '20px',
                    borderRadius: '10px',
                    backgroundColor: 'rgba(255, 255, 255, 0.93)',
                    fontFamily: 'Poppins, sans-serif',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.9)',
                    borderColor: '#fff',
                    borderStyle: 'solid',
                    borderWidth: '2px', 
                  }}>
                    A STREAMLINING FORM REQUEST & DOCUMENTS ARCHIVING AT ARGAO NATIONAL HIGH SCHOOL
                  </Typography>




              <Divider
                style={{
                  width: '87%',
                  margin: 'auto',
                  borderBottom: '2px solid white',
                  backgroundColor: 'white',
                  marginTop: isMobile ? '15px' : '20px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Add a subtle box shadow
                }}
              />
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{
                      background: `linear-gradient(45deg, #DEE7F3, #fff)`,
                      WebkitBackgroundClip: 'text', // For Safari
                      color: 'transparent',
                      textAlign: 'center',
                      marginTop: isMobile ? '1px' : '10px',
                      fontSize: 25,
                      padding: isMobile ? '1px' : '10px',
                      fontFamily: 'Poppins, sans-serif',
                      lineHeight: '1.5',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Darker background behind the text
                      borderRadius: '10px', // Optional: add border-radius for rounded corners
                    }}
                  >
                    "School Documents you need is readily available at your fingertips. Simply login or signup and navigate to the form request and complete it with ease."
                    </Typography>
                </Grid>

                <Grid display={'flex'} direction="column" alignItems="center" justifyContent="space-between" gap={2} sx={{py: isMobile ? 2 : 6 ,position:'center' }} >
                <Button href="login" size="large" variant="text"
                 sx={{':hover':{bgcolor: '#304ffe'}, borderRadius:'12px', padding: '15px 30px', color: 'white', backgroundColor: '#536dfe',
                textTransform: 'none', borderColo:'white' }}>LOGIN NOW
                </Button>
                <Button href="signup" size="large" variant="text" 
                sx={{':hover':{bgcolor: '#304ffe'}, 
                borderRadius:'12px', padding: '15px 20px', color: 'white', backgroundColor: '#536dfe', 
                textTransform: 'none'  }}>SIGN-UP NOW
                </Button>
                </Grid>


                <Grid  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '90vh' }}>
                <Typography variant="h3" component="div" style={{ color: 'white', textAlign: 'center', fontSize: 13 }}>
                Visit or message us on Facebook or Gmail by simply click the buttons below
                 </Typography>
                    <Stack  direction="row" alignItems="center" spacing={3} sx={{ py: 2 ,position:'center', }}> 
                        <Button
                            variant="outlined"
                            startIcon={<Avatar alt="Facebook Logo" src={fblogo} style={{ width: '15px', height: '15px' }} />}
                            href="https://www.facebook.com/profile.php?id=100063811972895"  //  URL of the Facebook page
                            target="_blank"  // Open the link in a new tab
                            rel="noopener noreferrer"  // Recommended for security
                            sx={{':hover':{bgcolor: '#536dfe'}, padding: '7px 25px', fontSize: '11px', color: 'white', borderColor: 'white'  }}
                            > Facebook
                        </Button>
                        <Button
                            variant="outlined"
                            startIcon={<Avatar alt="Gmail" src={gmail} style={{ width: '15px', height: '15px' }} />}
                            href="mailto:anhsproject69@gmail.com?subject=From%20ANHS%20Archive%20Hub"
                            target="_blank"  // Open the link in a new tab
                            rel="noopener noreferrer"  // Recommended for security
                            sx={{':hover':{bgcolor: '#536dfe'}, padding: '7px 30px', fontSize: '11px', color: 'white', borderColor: 'white'  }}
                            > Gmail
                        </Button>
                    </Stack>
                     <Typography sx={{color:'white', paddingBottom: isMobile ? '50px' : '', pt: isMobile ? '' : '50px'}}>Version 2</Typography>
                </Grid>
            </Grid>
         </Grid>       
    )
}
