import * as React from 'react';
import { Stack } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import build from '../../images/build.png';
import { Container } from '@mui/system';
import RequestHistoryTables from './components/data';
import NavigationBar from '../components/navigationbar/page';
import { useCache } from '../../components/cache-provider';
import useTheme from '@mui/system/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { UnderMaintenance } from '../under-maintenance/page';



export const RequestPage = () =>{
  const { addToCache, getFromCache } = useCache();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
      
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);


    return(
        //Image
        <Grid container component="main"  sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url(${build})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "110% 110%",
            overflowX: isMobile? 'hidden' : 'auto',
            overFlowY: 'auto'
          }} >

          
            <Grid >
                  <NavigationBar/>
                  <Container sx={{width: {xs:600, sm:1000, md:1200, lg:1400} , display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center'}}>
                    <Grid  alignItems="center" item xs={isMobile ? 9 : 14} spacing={1} >
                    <Typography variant="h1" component="div" style={{ color: 'white', textAlign: 'center', fontSize:isMobile? 20 : 30 , marginTop:isMobile? '30px' : '100px', letterSpacing: '1px' }}>
                    A STREAMLINING FORM REQUEST & DOCUMENTS ARCHIVING AT ARGAO NATIONAL HIGH SCHOOL
                    </Typography>
                    
                    <Typography variant="h3" component="div" style={{  textAlign: 'center', marginTop: '10px', fontSize:isMobile ? '15px' : 20, letterSpacing:'1px',   color: '#c8c9d3', }}>
                    "We're here to assist you in requesting the specific documents you need. Please let us know the type of documents you're requesting for, and we'll guide you through the process."
                    </Typography>
                   </Grid>

                
                <Box
                  sx={{
                    marginTop: isMobile ? '0' : "20px",
                    marginBottom: '20px',
                    width: isMobile ? '100%' : { xs: '100%', sm: 600, md: 800, lg: 1000 },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: isMobile ?  '100%' : '100%',
                    marginBottom:'50px'
                  }}
                >
                <Container sx={{ borderRadius: '8px', width: isMobile ? '76%' : '100%', p: 2 }}>
                  <RequestHistoryTables />
                  {/* <UnderMaintenance/> */}
                </Container>
                </Box>
            </Container>
      </Grid>
    </Grid>       
    )
}