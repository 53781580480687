export const role = [
    {
        value: 'Teacher',
        label: 'Teacher',
    },
    {
        value: 'Student',
        label: 'Student',
    },
    {
        value: 'Parent',
        label: 'Parent',
    },
    {
        value: 'Guardian',
        label: 'Guardian',
    },
]

