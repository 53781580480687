import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router-dom";
import LOGO from '../../../images/anhslogo.png';

export default function NavigationBar() {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="absolute" alignItems="center" sx={{ backgroundColor: '#3A78CD', marginTop: '0px' }}>
        <Toolbar>
          <img src={LOGO} style={{ marginRight: '15px', maxHeight: '55px' }} alt="Logo" />
          <Typography variant="h6" component="div" style={{ fontSize: 25, flexGrow: 2 }}>
            Archive Hub
          </Typography>
         {/* it will show menu when it comes to mobile-view */}
          {isMobile && (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
          )}

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <Button href='/home' color="inherit">Home</Button>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Button href='/request' color="inherit">Request</Button>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Button href='/contact' color="inherit">Contact Us</Button>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Button href='/about' color="inherit">About</Button>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <Button href='/logout' color="inherit">Logout</Button>
            </MenuItem>
          </Menu>

          { /*it will hide the  buttons when it comes to mobile view */}
          {!isMobile && (
            <>
              <Button href='/home' color="inherit" style={{ fontSize: 15, marginRight: '20px' }}>Home</Button>
              <Button href='/request' color="inherit" style={{ fontSize: 15, marginRight: '20px' }}>Request</Button>
              <Button href='/contact' color="inherit" style={{ fontSize: 15, marginRight: '20px' }}>Contact Us</Button>
              <Button href='/about' color="inherit" style={{ fontSize: 15, marginRight: '20px' }}>About</Button>
              <Button href='/logout' color="inherit" style={{ fontSize: 15, marginRight: '20px' }}>Logout</Button>
            </>
          )}
          

        </Toolbar>
      </AppBar>

    </Box>

  );
}
