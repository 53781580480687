import React from 'react';
import { Navigate, Outlet } from 'react-router';

export const Protected = () => {
    const token = localStorage.getItem('cache');
    
    return(
        token ? <Outlet /> : <Navigate to="/landing"/>
    )

}
