import { Button, Stack, CircularProgress, Modal, Box, Alert, Grid, TextField, FormControlLabel, Checkbox, Divider, Typography, Avatar } from "@mui/material";
import React, { useEffect, useState } from 'react';
import fblogo from '../../images/facebookImage.png';
import googleLogo from '../../images/googleImage.png';
import afc from '../../images/office.jpg';
import LOGO from '../../images/anhslogo.png';
import { auth, googleProvider } from "../../config/firebase";
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { useNavigate } from "react-router-dom";
import { useCache } from "../../components/cache-provider";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

export const LoginPage = () => {
  const navigate = useNavigate();
  const { addToCache } = useCache();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pwdMsg, setPwdMsg] = useState('');
  const [emMsg, setEmMsg] = useState('');
  const [validationError, setValidationError] = useState({
    email: false,
    password: false,
  });

  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  // Toggle the visibility of the password
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

//pop up modal alerts
  const openErrorModal = (message) => {
    setErrorMessage(message);
    setErrorModalOpen(true);
  };

  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };
//google sign in
  const handleGoogleSignin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const data = result.user;
      localStorage.setItem('cache', JSON.stringify({ accessToken: data.accessToken, uid: data.uid, email: data.uid }));
      navigate('/home');
    } catch (err) {
      console.error(err);
      openErrorModal('Google Sign-In Failed');
    }
  };
//login status
  const checkLoginStatus = () => {
    if (localStorage.getItem('cache')) {
      navigate('/home');
    }
  };

  useEffect(() => {
    checkLoginStatus();
  }, []);

  //validation errors
  const handleValidation = () => {
    const updatedValidationError = {
      email: !email.trim(),
      password: !password.trim(),
    };
    setPwdMsg('Password is Required');
    setEmMsg("Please confirm your email and try again. If you haven't registered yet, kindly proceed with the sign-up process first.");
    setValidationError(updatedValidationError);
    return !Object.values(updatedValidationError).some((error) => error);
  };

  //remember me 
  const handleRememberChange = (checked) => {
    //to check if its functional --
    // console.log("Remember Me Checked:", checked);
    setRemember(checked);
    if (!checked) {
      localStorage.removeItem('rememberedEmail');
    }
  };

  const handleSignIn = async () => {
    if (handleValidation()) {
      try {
        setLoading(true);
        const result = await signInWithEmailAndPassword(auth, email, password);
        const data = result.user;
        if (remember) {
          addToCache({ accessToken: data.accessToken, uid: data.uid, email: data.email }, remember);
        } else {
          localStorage.setItem('cache', JSON.stringify({ accessToken: data.accessToken, uid: data.uid, email: data.email }));
        }

        navigate('/home');
      } catch (err) {
        console.error(err);
        if (err.code === 'auth/wrong-password') {
          openErrorModal('Incorrect Password. Please check your password and try again.');
          setValidationError({ email: false, password: true }); // Set only password error
        } else if (err.code === 'auth/network-request-failed') {
          openErrorModal('Unable to connect. Please check your internet connection.');
          setValidationError({ email: true, password: true }); // Set both email and password errors
        } else if (err.code === 'auth/user-not-found') {
          openErrorModal('User not found. Please check your email.');
          setValidationError({ email: true, password: false }); // Set only email error
        } else if (err.code === 'auth/invalid-email') {
          openErrorModal('Invalid email format. Please provide a valid email.');
          setValidationError({ email: true, password: false }); // Set only email error
        } else if (err.code === 'auth/too-many-requests') {
          openErrorModal('Too many unsuccessful sign-in attempts. For security reasons, your account has been temporarily locked. Please try again later or reset your password.');
          setValidationError({ email: true, password: false });
        } else if (err.code === 'auth/invalid-login-credentials') {
          openErrorModal('Invalid email or password. Please try again.');
          setValidationError({ email: true, password: false }); 
        } else {
          openErrorModal('Authentication failed. Please try again later.');
          setValidationError({ email: true, password: true }); // Set both email and password errors
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Grid bgcolor={'#FFFBF5'} elevation={8} container component="main" sx={{ height: '100vh' }} justifyContent={"center"}   overflow={"auto"}>
      <Grid item xs={12} sm={5} md={5} square container justifyContent="center" alignItems="center">
        <Box backgroundColor={'white'} 
        sx={{ height: '93%',
         width: '100%',
          padding: 3,
           boxShadow: 2,
           '@media (min-width:600px)': {
            height: '93%',
            width: '80%',
            padding: 3,
            boxShadow: 2,
              }
           }}>
          <Stack spacing={2}>

                <Grid container  alignItems="flex-end">
                <Grid item xs={8}>
                <Typography sx={{color:'#528AD7', height:'100px', }} variant="h1" >Archive Hub</Typography>
                </Grid>
                <Grid item xs={1}>
                <img src={LOGO} alt="logo" />
                </Grid>
                </Grid>

                    <h2 color={{color: '#363636'}}>User Login Portal</h2>
                    <Stack spacing={100} >
                            <Box>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                type="email"
                                autoFocus
                                onChange={(e) => setEmail(e.target.value)}
                                error={validationError.email}
                                helperText={validationError.email ? emMsg : emailError} 
                                onFocus={() => {
                                setValidationError({ ...validationError, email: false });
                                setEmailError(''); {/* Resetting error message when focused */}
                              }}
                            />
                            
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                id="password"
                                  type={showPassword ? 'text' : 'password'}      
                                onChange={(e) => setPassword(e.target.value)}
                                error={validationError.password}
                                helperText={validationError.password ? pwdMsg : passwordError} 
                                onFocus={() => {
                                  setValidationError({ ...validationError, password: false });
                                  setPasswordError(''); // Resetting error message when focused
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton onClick={handleTogglePassword} edge="end">
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                            />
                            <Button
                                onClick={handleSignIn}
                                variant="contained"
                                fullWidth
                                size="large"
                                sx={{ color: 'white', backgroundColor: 'main', position: 'relative' }}
                                disabled={loading} // Disable the button when loading
                            >
                            {loading && <CircularProgress size={24} sx={{ color: 'white', position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px' }} />} 
                            {/* Show CircularProgress when loading */}
                                Login
                            </Button>
                          

                            </Box>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <FormControlLabel control={<Checkbox checked={remember} onChange={(e) => handleRememberChange(e.target.checked)} />}
                         label="Remember Me?" />
                        <Button href="/forget" variant="text" style={{ textTransform: 'none' }}>Forgot your password?</Button>
                    </Stack>

                    <Divider gutterBottom variant="body1"/>


                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={4}>
                        <Typography variant="body1">
                            Don't have an account?{' '}
                            <Button href="/signup" variant="text" style={{ textTransform: 'none' }}>SignUp Here!</Button>
                        </Typography>
                    </Stack>


                    <Stack display="flex" justifyContent="center">
                
                    <p  variant="body1" style={{ color: 'gray', marginLeft: '10px', marginRight: '10px', display: 'flex' }}>
                        <div style={{ flex: 1, borderTop: '1px solid gray', margin: '10px' }}></div>
                        Or 
                        <div style={{ flex: 1, borderTop: '1px solid gray', margin: '10px' }}></div>
                    </p>
                    <Button
                      variant="contained"
                      startIcon={<Avatar alt="Google Logo" src={googleLogo} style={{ width: '15px', height: '15px' }} />}
                      sx={{
                        boxShadow: 4,
                        fontSize: '13px',
                        color:'black',
                        width: '100%',
                        marginTop: '5px',
                        background: 'linear-gradient(45deg, #C4D2E6 30%, #89B2EF 90%)', // Set linear gradient as background
                        '&:hover': {
                          background: 'linear-gradient(45deg, #89B2EF 30%, #C4D2E6 90%)', // Adjust gradient for hover effect
                        },
                      }}
                      onClick={handleGoogleSignin}
                    >
                      Continue with Google
                    </Button>


                    </Stack>



                <Modal
                open={errorModalOpen}
                onClose={closeErrorModal}
                aria-labelledby="error-modal"
                aria-describedby="error-message"
                >
                  <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'white',  // Set background color
                    borderRadius: '8px',  // Add border-radius for a rounded look
                    boxShadow: 2,
                    p: 4,
                  }}>
                  <Alert severity="error" onClose={closeErrorModal}  sx={{
                    '& .MuiAlert-icon': {
                      animation: 'pulse 1s infinite',  // Add animation for the icon
                      fontSize: '32px',
                    },
                    '@keyframes pulse': {
                      '0%': {
                        opacity: 0.3,
                        color: '#8B0000', // Dark Red
                      },
                      '50%': {
                        opacity: 0.7,
                        color: '#FF0000', // Red
                      },
                      '100%': {
                        opacity: 0.3,
                        color: '#8B0000', // Dark Red
                      },
                    }, 
                  }}>
                    {errorMessage}
                  </Alert>
                </Box>
              </Modal>
          </Stack>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${afc})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        
        }}
      />
    </Grid>
  );
}

                  