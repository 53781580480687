import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Box, Button, Grid, CircularProgress, IconButton } from '@mui/material';
import RequestModal, { TableModal } from './modal';
import { Typography } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

//firebase
import { db } from '../../../config/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import useTheme from '@mui/system/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { format } from 'date-fns';

const columns = [
  { id: 'requestStatus', label: 'Status', minWidth: 100 },
  { id: 'firstname', label: ' Student First Name', minWidth: 100 },
  { id: 'lastname', label: 'Student Last Name', minWidth: 100 },
  { id: 'formType', label: 'Request Type', minWidth: 100 },
  {
    id: 'requestDateTime',
    label: 'Requested Date',
    minWidth: 120,
  },
  { id: 'requestCode', label: 'Request Code', minWidth: 100 },
  { id: 'lrn', label: 'LRN', minWidth: 100 },
];

export default function RequestHistoryTables() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [userData, setUserData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [initialFetch, setInitialFetch] = React.useState(false);
  const [studentData, setStudentData] = useState([]);

  // Define userDataArray state to store fetched data
  const [userDataArray, setUserDataArray] = React.useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Fetch user data from FireStore
  const fetchUserData = async () => {
    const cache = localStorage.getItem('cache');
    const parsedCache = cache ? JSON.parse(cache) : null;

    // Check if parsedCache and parsedCache.uid are defined before accessing
    const userId = parsedCache && parsedCache.uid ? parsedCache.uid.toString() : null;

    const usersCollectionRef = collection(db, 'users');
    const userQuery = query(usersCollectionRef, where('userId', '==', userId));

    try {
      const userQuerySnapshot = await getDocs(userQuery);

      if (userQuerySnapshot.size > 0) {
        const userDocRef = userQuerySnapshot.docs[0].ref;
        const childCollectionRef = collection(userDocRef, 'requests');
        const resultData = await getDocs(childCollectionRef);

        // Store the fetched data in userDataArray state
        const userDataArray = resultData.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setUserDataArray(userDataArray);

        // Filter data based on search query
        const filteredData = userDataArray.filter((user) =>
          Object.values(user).some((value) =>
            String(value).toLowerCase().includes(searchQuery.toLowerCase())
          )
        );
        setUserData(filteredData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setLoading(false);
    }
  };

  const fetchStudentData = async () => {
    const studentCollectionRef = collection(db, 'students');

    try {
      const querySnapshot = await getDocs(studentCollectionRef);

      if(querySnapshot.size > 0){
        const resultData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        
         setStudentData(resultData);

        console.log('studentData', resultData.id);
        resultData.forEach((student) => {
          console.log('studentData: ', student.id);
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

    // useEffect hook for fetching data when the component mounts
    useEffect(() => {
      const fetchData = async () => {
        // Fetch student data
        await fetchStudentData();
    
        // Now that student data has been fetched, fetch user data
        fetchUserData();
      };
    
      // Fetch data when the component mounts
      if (!initialFetch || searchQuery !== '') {
        setInitialFetch(true);
        fetchData();
      }
    }, [initialFetch, searchQuery]);
  
  

  const getStatusLabel = (status) => {
    // Add any other status labels as needed
    switch (status) {
      case 'Pending':
        return 'Pending';
      case 'Approved':
        return 'Approved';
      case 'Declined':
        return 'Declined';
      default:
        return 'Pending';
    }
  };

  const reloadTable = () => {
    setLoading(true);
    if (!initialFetch) {
      setInitialFetch(true);
      fetchUserData(); // Fetch data only on the first reload click
    } else {
      fetchUserData(); // Fetch data on subsequent reload clicks
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Grid container sx={{ width: '100%', alignItems: 'center', paddingTop: '10px', paddingBottom: '30px', paddingLeft: '10px', paddingRight: '10px' }}>
        <Box item xs={12} md={8} sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: isMobile ? 'flex-start' : 'space-between', }}>
          <Typography sx={{ fontSize: isMobile ? '15px' : '24px', marginRight: isMobile ? '1px' : '400px', paddingRight: isMobile ? '1px' : '10px' }}>
            Request History
          </Typography>
          
          <InputBase
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearchChange}
          startAdornment={
            <SearchIcon
              sx={{ marginLeft: '10px', marginRight: '5px', color: 'grey' }}
            />
          }
          sx={{
            marginLeft: isMobile ? '10px' : '20px',
            flex: 1,
            border: '1px solid #ced4da',
            borderRadius: '5px',
            padding: '5px',
          }}
        />
          <IconButton aria-label='Reload Table' sx={{ marginRight: isMobile ? '10px' : '20px' }} onClick={reloadTable}>
            <ReplayIcon />
          </IconButton>
          <Button
            size="large"
            variant="text"
            sx={{
              borderRadius: isMobile ? '6px' : '5px',
              padding: isMobile ? '3px 5px' : '2px 2px',
              color: 'white',
              textTransform: 'none',
              ':hover': { bgColor: 'darkblue' },
            }}
          >
            <TableModal studentInfo={studentData}/>
          </Button>
        </Box>
      </Grid>
      <TableContainer sx={{ maxHeight:isMobile? 400 : 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
  {loading ? (
    <TableRow>
      <TableCell colSpan={columns.length} align="center">
        <CircularProgress size={50} sx={{ color: '#3A78CD' }} />
      </TableCell>
    </TableRow>
  ) : (
    userData
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row) => (
        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
          {columns.map((column) => {
            const value = row[column.id];
            return (
              <TableCell
  key={column.id}
  align={column.align}
  style={{
    color:
      column.id === 'requestStatus'
        ? value === 'Pending'
          ? 'black'
          : value === 'Approved'
          ? 'green'
          : value === 'Declined'
          ? 'red'
          : 'inherit'
        : 'inherit',
    fontWeight:
      column.id === 'requestStatus' &&
      (value === 'Pending' || value === 'Approved' || value === 'Declined')
        ? 'bold'
        : 'normal',
  }}
>
  {column.format && typeof column.format === 'function'
    ? column.format(value)
    : column.id === 'requestDateTime' && value && value.toDate
    ? format(value.toDate(), 'MMM dd, yyyy : hh:mm a')
    : column.id === 'requestStatus' ? (
      <span>
        {getStatusLabel(value)}
      </span>
    ) : (
      value
    )}
</TableCell>
            );
          })}
        </TableRow>
      ))
  )}
</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={userData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
