import React from 'react'
import { Typography, Container, Button,Box } from '@mui/material';

export const VerifyPage = () => {
  return (
    <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    }}
  >
    <Container component="main" maxWidth="xs">
      <div>
        <Typography variant="h5" gutterBottom>
          Please Verify Your Email
        </Typography>
        <Typography variant="body1" paragraph>
          Before you can register, please check your email and click on the verification link
          we sent you.
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          Didn't receive the email? Click the button below to resend the verification email.
        </Typography>
        <Button
          type="button"
          fullWidth
          variant="outlined"
          color="primary"
          sx={{
            '&:hover': {
              backgroundColor: '#1976D2', 
              color: '#fff', 
            },
          }}
        >
          Resend Verification Email
        </Button>
      </div>
    </Container>
  </Box>
  )
}
